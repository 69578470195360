<template>
  <DetailTemplate
    :customClass="'task-detail detail-page'"
    v-if="getPermission('task:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ taskSubject }}
          </h1>
          <Priority :priority.sync="taskDetail.priority"></Priority>
          <CustomStatus
            :status.sync="taskDetail.status"
            endpoint="tasks/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-skeleton-loader
        class="custom-skeleton d-inline-block"
        v-if="pageLoading"
        type="button"
      >
      </v-skeleton-loader>
      <v-menu
        v-else
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="4" class="custom-border-right">
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="max-height: 69vh; position: relative"
            > -->
            <table class="width-100">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Task #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ taskDetail.barcode }}
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Reference #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="taskDetail.reference">{{
                    taskDetail.reference
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Reference #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Subject
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="taskDetail.subject">{{
                    taskDetail.subject
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Subject</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Hourly Rate
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="taskDetail.hourly_rate">{{
                    taskDetail.hourly_rate
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Hourly Rate</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Start Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="taskDetail.start_date">{{
                    formatDate(taskDetail.start_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Start Date</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Due Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="taskDetail.due_date">{{
                    formatDate(taskDetail.due_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Due Date</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Finish Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="taskDetail.finish_date">{{
                    formatDate(taskDetail.finish_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">Not Finished Yet</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Recurrence
                  <template v-if="taskDetail.recurrence == 8"
                    >(Custom)</template
                  >
                </td>
                <td class="pb-2 font-size-18 font-weight-600">
                  <template v-if="taskDetail.recurrence != 8">
                    <template v-if="taskDetail.recurrence_text">{{
                      taskDetail.recurrence_text
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Recurrence</em></template
                    >
                  </template>
                  <template v-else>
                    <template v-if="taskDetail.duration_type_text"
                      >Every {{ taskDetail.duration }}
                      {{ taskDetail.duration_type_text }}</template
                    >
                    <template v-else
                      ><em class="text--secondary">No Recurrence</em></template
                    >
                  </template>
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Total Logged Hours
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="taskDetail.total_logged_hours">{{
                    taskDetail.total_logged_hours
                  }}</template>
                  <template v-else
                    ><em class="text--secondary"
                      >No Time Logged Till Now</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Total Cycle
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="taskDetail.total_cycle">{{
                    taskDetail.total_cycle
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Total Cycle</em></template
                  >
                </td>
              </tr>
            </table>

            <div class="mt-4 custom-border-top">
              <h3 class="py-2 font-size-18 text-capitalize">description</h3>
              <template v-if="taskDetail.description">
                <read-more
                  class="custom-read-more font-size-18 pb-1"
                  more-str="read more"
                  :text="taskDetail.description"
                  link="#"
                  less-str="read less"
                  :max-chars="200"
                >
                </read-more>
              </template>
              <template v-else
                ><em class="text--secondary"
                  >No description for this task</em
                ></template
              >
            </div>
            <div class="mt-5 custom-border-top">
              <v-layout class="py-2">
                <v-flex>
                  <h3 class="font-size-18 text-capitalize">members</h3>
                </v-flex>
                <v-flex
                  class="text-right"
                  v-if="getPermission('task:update') && taskDetail.status > 1"
                >
                  <v-btn icon v-on:click="memberDialog = true">
                    <v-icon color="cyan">mdi-cog</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <template
                v-if="taskDetail.members && taskDetail.members.length > 0"
              >
                <v-list-item
                  v-for="(member, index) in taskDetail.members"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-img
                      :lazy-src="$defaultProfileImage"
                      :src="$assetAPIURL(member.profile_logo)"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-18">{{
                      member.full_name
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      class="font-size-14 text-capitalize line-height-1-5"
                      >total logged hours :
                      {{
                        lodash.toSafeInteger(member.time_logged_in_minutes / 60)
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="getPermission('task:delete') && taskDetail.status > 1"
                  >
                    <v-btn icon v-on:click="deleteMember(member)">
                      <v-icon color="red lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <template v-else>
                <template v-if="!pageLoading">
                  <p
                    class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      width="30"
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image mx-2"
                    />
                    Uhh... There are no member associated with this task at the
                    moment.
                  </p>
                </template>
              </template>
            </div>
            <!-- </perfect-scrollbar> -->
          </v-col>
          <v-col md="8">
            <v-tabs
              v-model="taskTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent"
            >
              <template v-for="(tab, index) in tabs">
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  :key="index"
                  :href="'#' + tab.key"
                  :disabled="tab.disabled"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  {{ tab.title }}
                </v-tab>
              </template>
            </v-tabs>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="max-height: 64vh; position: relative"
            > -->
            <v-tabs-items v-model="taskTab">
              <v-tab-item value="checklist-item">
                <v-container fluid>
                  <v-layout v-if="taskDetail.status > 1">
                    <v-flex>
                      <v-btn
                        class="my-2 mr-2 custom-bold-button white--text"
                        color="cyan"
                        small
                        :disabled="isPageLoading"
                        v-on:click="addCheckListItem"
                      >
                        Add Item
                      </v-btn>
                      <v-btn
                        v-if="newChecklistItems.length > 0"
                        class="my-2 ml-2 custom-bold-button white--text"
                        color="cyan"
                        small
                        :disabled="isPageLoading"
                        v-on:click="saveCheckListItem"
                      >
                        Save Item
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <table
                    class="width-100"
                    v-if="
                      newChecklistItems.length > 0 || checklistItems.length > 0
                    "
                  >
                    <tr
                      v-for="(row, index) in newChecklistItems"
                      :key="index"
                      :class="{ opacity05: row.status === 1 }"
                    >
                      <td text-align="center" class="px-2 py-2" width="50">
                        <v-checkbox
                          color="cyan"
                          class="hide-margin"
                          hide-details
                          v-model.number="row.status"
                          :value="1"
                          :disabled="isPageLoading"
                        ></v-checkbox>
                      </td>
                      <td text-align="center" class="px-2 py-2">
                        <v-text-field
                          v-model.trim="row.subject"
                          dense
                          filled
                          label="Subject"
                          solo
                          flat
                          color="cyan"
                          hide-details
                          :disabled="isPageLoading"
                          :loading="isPageLoading"
                        ></v-text-field>
                      </td>
                      <td text-align="center" class="px-2 py-2" width="50">
                        <v-btn
                          icon
                          :disabled="isPageLoading"
                          v-on:click="removeCheckListItem(index)"
                        >
                          <v-icon color="red lighten-1">mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr
                      v-for="(row, index) in checklistItems"
                      :key="index"
                      :class="{ opacity05: row.status === 1 }"
                    >
                      <td text-align="center" class="px-2 py-2" width="50">
                        <v-checkbox
                          color="cyan"
                          class="hide-margin"
                          hide-details
                          v-model.number="row.status"
                          :value="1"
                          :disabled="isPageLoading"
                          v-on:change="updateCheckListItem(row)"
                        ></v-checkbox>
                      </td>
                      <td text-align="center" class="px-2 py-2">
                        <v-text-field
                          v-model.trim="row.subject"
                          dense
                          filled
                          label="Subject"
                          solo
                          flat
                          color="cyan"
                          hide-details
                          :disabled="isPageLoading"
                          :loading="isPageLoading"
                          @keydown.enter="updateCheckListItem(row)"
                          @keydown.tab="updateCheckListItem(row)"
                        ></v-text-field>
                      </td>
                      <td text-align="center" class="px-2 py-2" width="50">
                        <v-btn
                          icon
                          :disabled="isPageLoading"
                          v-on:click="deleteCheckListItem(row.id)"
                        >
                          <v-icon color="red lighten-1">mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </table>

                  <v-col md="12" v-else>
                    <template v-if="!pageLoading">
                      <p
                        class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          width="30"
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image mx-2"
                        />
                        Uhh... There are no checklist items for this task at the
                        moment.
                      </p>
                    </template>
                  </v-col>
                </v-container>
              </v-tab-item>
              <v-tab-item value="timesheet">
                <v-container fluid>
                  <v-layout v-if="taskDetail.status > 1">
                    <v-flex>
                      <v-btn
                        v-on:click="addTimesheet"
                        class="my-2 mr-2 custom-bold-button white--text"
                        color="cyan"
                        small
                        :disabled="timesheetLoading"
                      >
                        <template v-if="timesheetActive">Close</template>
                        <template v-else>Add Timesheet</template>
                      </v-btn>
                      <v-btn
                        v-if="timesheetActive"
                        class="my-2 ml-2 custom-bold-button white--text"
                        color="cyan"
                        small
                        v-on:click="saveTimesheet"
                        :disabled="timesheetLoading"
                      >
                        Save Timesheet
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-row>
                    <v-col md="12" v-if="timesheetActive">
                      <table class="width-100">
                        <tr>
                          <td class="py-2">
                            <label class="font-weight-500 font-size-16"
                              >Start Date</label
                            >
                            <DatePicker
                              :pageLoading.sync="isPageLoading"
                              solo
                              :placeholder="'Start Date'"
                              v-on:update:date-picker="setStartDate"
                            ></DatePicker>
                          </td>
                          <td class="py-2">
                            <label class="font-weight-500 font-size-16"
                              >End Date</label
                            >
                            <DatePicker
                              :pageLoading.sync="isPageLoading"
                              solo
                              :placeholder="'End Date'"
                              v-on:update:date-picker="setEndDate"
                            ></DatePicker>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">
                            <label class="font-weight-500 font-size-16"
                              >Start Time</label
                            >
                            <TimePicker
                              :pageLoading.sync="isPageLoading"
                              solo
                              :placeholder="'Start Time'"
                              v-on:update:time-picker="setStartTime"
                            ></TimePicker>
                          </td>
                          <td class="py-2">
                            <label class="font-weight-500 font-size-16"
                              >End Time</label
                            >
                            <TimePicker
                              :pageLoading.sync="isPageLoading"
                              solo
                              :placeholder="'End Time'"
                              v-on:update:time-picker="setEndTime"
                            ></TimePicker>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2" colspan="2">
                            <label class="font-weight-500 font-size-16"
                              >Member</label
                            >
                            <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              :items="taskMemberList"
                              :disabled="isPageLoading"
                              :loading="isPageLoading"
                              v-model.trim="timesheetCreate.member"
                              label="Member"
                              solo
                              flat
                              item-color="cyan"
                              item-text="full_name"
                              item-value="id"
                              hide-details
                              :rules="[
                                validateRules.required(
                                  timesheetCreate.member,
                                  'Member'
                                )
                              ]"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="'No Member Found.'"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2" colspan="2">
                            <label class="font-weight-500 font-size-16"
                              >Note</label
                            >
                            <editor v-model="timesheetCreate.description" />
                          </td>
                        </tr>
                      </table>
                    </v-col>
                    <v-col md="12" v-if="timesheets.length > 0">
                      <table class="width-100">
                        <tr
                          v-for="(row, index) in timesheets"
                          :key="index"
                          :class="{ 'custom-border-top': index > 0 }"
                        >
                          <td width="150" align="center">
                            <v-avatar size="100">
                              <v-img
                                :src="row.profile_logo"
                                :lazy-src="$defaultProfileImage"
                              ></v-img>
                            </v-avatar>
                          </td>
                          <td class="py-2">
                            <table class="width-100">
                              <tr>
                                <td
                                  width="300"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Member
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  {{ row.full_name }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Started At
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  {{ formatDateTime(row.started_at) }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Finished At
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  {{ formatDateTime(row.finished_at) }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Total Duration (in hours)
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  {{ lodash.toSafeInteger(row.duration / 60) }}
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td class="py-2" width="50">
                            <v-btn
                              icon
                              :disabled="isPageLoading"
                              v-on:click="deleteTimesheet(row.id)"
                            >
                              <v-icon color="red lighten-1">mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-col>
                    <v-col md="12" v-else>
                      <template v-if="!pageLoading">
                        <p
                          class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            width="30"
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image mx-2"
                          />
                          Uhh... There are no timesheet for this task at the
                          moment.
                        </p>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="comment">
                <v-container fluid>
                  <v-layout v-if="taskDetail.status > 1">
                    <v-flex>
                      <v-btn
                        v-on:click="addComment"
                        class="my-2 mr-2 custom-bold-button white--text"
                        color="cyan"
                        small
                        :disabled="commentLoading"
                      >
                        <template v-if="commentActive">Close</template>
                        <template v-else>Add Comment</template>
                      </v-btn>
                      <v-btn
                        v-if="commentActive"
                        class="my-2 ml-2 custom-bold-button white--text"
                        color="cyan"
                        small
                        :disabled="commentLoading"
                        v-on:click="saveComment"
                      >
                        Save Comment
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-row>
                    <template v-if="commentActive">
                      <v-col md="12">
                        <editor v-model="commentCreate.description" />
                      </v-col>
                      <v-col md="12">
                        <FileTemplate
                          allowUpload
                          isMinDisplay
                          v-on:file:updated="updateCommentAttachment"
                        ></FileTemplate>
                      </v-col>
                    </template>
                    <v-col md="12" v-if="comments.length > 0">
                      <v-timeline dense clipped>
                        <v-timeline-item
                          color="white"
                          v-for="(row, index) in comments"
                          :key="index"
                          large
                        >
                          <template v-slot:icon>
                            <v-avatar>
                              <v-img
                                :src="row.profile_logo"
                                :lazy-src="$defaultProfileImage"
                              ></v-img>
                            </v-avatar>
                          </template>
                          <v-row class="pt-1">
                            <v-col md="3">
                              <em
                                v-tippy="{ arrow: true }"
                                content="Created At"
                                class="font-weight-600"
                                >{{ formatDateTime(row.added_at) }}</em
                              ><br />
                              <em
                                v-tippy="{ arrow: true }"
                                content="Created By"
                                class="font-weight-600 text-capitalize"
                                >{{ row.full_name }}</em
                              >
                            </v-col>
                            <v-col md="9">
                              <div class="task-comment-delete">
                                <v-btn
                                  icon
                                  :disabled="isPageLoading"
                                  v-on:click="deleteComment(row.id)"
                                >
                                  <v-icon color="red lighten-1"
                                    >mdi-delete</v-icon
                                  >
                                </v-btn>
                              </div>
                              <div class="caption mb-2">
                                <read-more
                                  class="custom-read-more font-weight-600 font-size-16 pb-1 pr-2"
                                  more-str="read more"
                                  :text="row.description || ''"
                                  link="#"
                                  less-str="read less"
                                  :max-chars="60"
                                >
                                </read-more>
                              </div>
                              <FileTemplate
                                isMinDisplay
                                :allowDelete="false"
                                :attachments.sync="row.attachments"
                              ></FileTemplate>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </v-col>
                    <v-col md="12" v-else>
                      <template v-if="!pageLoading">
                        <p
                          class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            width="30"
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image mx-2"
                          />
                          Uhh... There are no comment for this task at the
                          moment.
                        </p>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="attachment">
                <v-container fluid>
                  <v-layout v-if="taskDetail.status > 1">
                    <v-flex>
                      <v-btn
                        v-on:click="addAttachment"
                        class="my-2 mr-2 custom-bold-button white--text"
                        color="cyan"
                        small
                      >
                        Add Attachment
                      </v-btn>
                      <v-btn
                        v-if="attachmentActive"
                        class="my-2 ml-2 custom-bold-button white--text"
                        color="cyan"
                        small
                        v-on:click="saveAttachment"
                      >
                        Save Attachment
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-row>
                    <v-col md="12" v-if="attachmentActive">
                      <FileTemplate
                        allowUpload
                        isMinDisplay
                        v-on:file:updated="updateAttachment"
                      ></FileTemplate>
                    </v-col>
                    <v-col md="12" v-if="attachments.length > 0">
                      <FileTemplate
                        isMinDisplay
                        :allowDelete="false"
                        :attachments="attachments"
                      ></FileTemplate>
                    </v-col>
                    <v-col md="12" v-else>
                      <template v-if="!pageLoading">
                        <p
                          class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            width="30"
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image mx-2"
                          />
                          Uhh... There are no attachment for this task at the
                          moment.
                        </p>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
            <!-- </perfect-scrollbar> -->
          </v-col>
        </v-row>
      </v-container>
      <template v-if="getPermission('task:update')">
        <Dialog :commonDialog.sync="memberDialog">
          <template v-slot:title>Members</template>
          <template v-slot:body>
            <v-container class="py-0">
              <v-row>
                <v-col md="12" class="py-0">
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    :items="memberList"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    v-model.trim="members"
                    label="Members"
                    solo
                    flat
                    item-color="cyan"
                    item-text="full_name"
                    item-value="id"
                    hide-details
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 3">
                        <span>{{ item.full_name }}</span>
                      </v-chip>
                      <span v-if="index === 3" class="grey--text font-size-12">
                        (+{{ members.length - 3 }} others)
                      </span>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Member Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:action>
            <v-container class="py-0">
              <v-row>
                <v-col md="12" class="text-right">
                  <v-btn
                    v-on:click="memberDialog = false"
                    :disabled="pageLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="updateMembers"
                    color="cyan"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Dialog>
      </template>
      <template v-if="getPermission('task:delete')">
        <MemberDelete :deleteDialog="deleteMemberDialog">
          <template v-slot:title>Delete Confirmation</template>
          <template v-slot:text>
            <h3 class="font-size-16 font-weight-500 delete-text">
              Once you delete
              <span class="font-weight-700">{{ deleteDetail.full_name }}</span
              >, you won't be able to retrieve this later. Are you sure you want
              to delete
              <span class="font-weight-700">{{ deleteDetail.full_name }}</span>
              ?
            </h3>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="deleteLoading"
              :loading="deleteLoading"
              class="mx-2 custom-bold-button white--text"
              color="red lighten-1"
              v-on:click="deleteMemberConfirm"
            >
              Yes, Delete it
            </v-btn>

            <v-btn
              :disabled="deleteLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="deleteMemberDialog = false"
            >
              No, Keep it
            </v-btn>
          </template>
        </MemberDelete>
      </template>
    </template>
    <template v-slot:footer> </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH, POST, DELETE } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import Priority from "@/view/pages/partials/Priority";
import Dialog from "@/view/pages/partials/Dialog";
import MemberDelete from "@/view/pages/partials/Delete.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import TimePicker from "@/view/pages/partials/Timepicker.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "task-detail",
  title: "Detail Task",
  data() {
    return {
      task: null,
      taskTab: null,
      pageLoading: false,
      deleteLoading: false,
      commentLoading: false,
      checklistLoading: false,
      timesheetLoading: false,
      timesheetActive: false,
      attachmentLoading: false,
      attachmentActive: false,
      commentActive: false,
      memberDialog: false,
      deleteMemberDialog: false,
      moreActions: [],
      memberList: [],
      members: [],
      newChecklistItems: [],
      checklistItems: [],
      comments: [],
      timesheets: [],
      attachments: [],
      commentCreate: new Object({
        description: null,
        attachments: []
      }),
      attachmentCreate: new Object({
        attachments: []
      }),
      timesheetCreate: new Object({
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        member: null,
        description: null
      }),
      deleteDetail: new Object(),
      taskDetail: new Object({
        subject: null,
        description: null,
        barcode: null,
        reference: null,
        hourly_rate: null,
        priority: 0,
        status: 0,
        recurrence: null,
        duration: null,
        duration_type: null,
        total_cycle: null,
        start_date: null,
        finish_date: null,
        attachments: [],
        members: [],
        due_date: null
      }),
      tabs: [
        {
          title: "Checklist Items",
          icon: "mdi-phone",
          key: "checklist-item",
          disabled: false
        },
        {
          title: "Timesheets",
          icon: "mdi-account-multiple",
          key: "timesheet",
          disabled: false
        },
        {
          title: "Comments",
          icon: "mdi-account-multiple",
          key: "comment",
          disabled: false
        },
        /*{
          title: "Reminders",
          icon: "mdi-account-multiple",
          key: "reminder",
          disabled: false
        },*/
        {
          title: "Attachments",
          icon: "mdi-account-multiple",
          key: "attachment",
          disabled: false
        }
        /*{
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false
        }*/
      ]
    };
  },
  components: {
    // FileTemplate,
    DetailTemplate,
    MemberDelete,
    DatePicker,
    TimePicker,
    CustomStatus,
    Priority,
    Dialog,
    FileTemplate,
    editor: TinyMCE
  },
  methods: {
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("task.create", {
              query: {
                duplicate: _this.task
              }
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("task.update", {
              params: {
                id: _this.task
              }
            })
          );
          break;
        case "mark_as_completed":
          _this.updateTask(1);
          break;
        case "mark_as_testing":
          _this.updateTask(2);
          break;
        case "mark_as_inprogress":
          _this.updateTask(3);
          break;
        case "download_pdf":
          _this.downloadPdf();
          break;
        case "print":
          _this.printPage();
          break;
      }
    },
    updateTask(status) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "tasks/" + _this.task + "/status",
          data: { status }
        })
        .then(() => {
          _this.getTask();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    downloadPdf() {},
    printPage() {},
    deleteMember(member) {
      this.deleteDetail = member;
      this.deleteMemberDialog = true;
    },
    deleteMemberConfirm() {
      const _this = this;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "tasks/" + _this.task + "/members/" + _this.deleteDetail.id
        })
        .then(() => {
          _this.getTask();
          _this.deleteDetail = new Object();
          _this.deleteMemberDialog = false;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.deleteLoading = false;
        });
    },
    updateMembers() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "tasks/" + _this.task + "/members",
          data: { members: _this.members }
        })
        .then(() => {
          _this.getTask();
          _this.memberDialog = false;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getMembers() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "tasks/members"
        })
        .then(({ data }) => {
          _this.memberList = data;
        })
        .catch(error => {
          _this.logError(error);
        });
    },
    getChecklistItems() {
      const _this = this;
      _this.checklistLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task + "/checklist-items"
        })
        .then(({ data }) => {
          _this.checklistItems = data;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.checklistLoading = false;
        });
    },
    addCheckListItem() {
      this.newChecklistItems.push({
        subject: null,
        status: null
      });
    },
    removeCheckListItem(index) {
      this.newChecklistItems.splice(index, 1);
    },
    saveCheckListItem() {
      const _this = this;
      _this.checklistLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "tasks/" + _this.task + "/checklist-items",
          data: { items: _this.newChecklistItems }
        })
        .then(() => {
          _this.newChecklistItems = new Array();
          _this.getChecklistItems();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.checklistLoading = false;
        });
    },
    updateCheckListItem(row) {
      const _this = this;
      _this.checklistLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "tasks/" + _this.task + "/checklist-items/" + row.id,
          data: row
        })
        .then(() => {
          _this.newChecklistItems = new Array();
          _this.getChecklistItems();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.checklistLoading = false;
        });
    },
    deleteCheckListItem(itemid) {
      const _this = this;
      _this.checklistLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "tasks/" + _this.task + "/checklist-items/" + itemid
        })
        .then(() => {
          _this.getChecklistItems();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.checklistLoading = false;
        });
    },
    getComments() {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task + "/comments"
        })
        .then(({ data }) => {
          _this.comments = data;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    addComment() {
      this.commentActive = !this.commentActive;
      this.commentCreate = new Object({
        description: null,
        attachments: []
      });
    },
    updateCommentAttachment(param) {
      this.commentCreate.attachments = param;
    },
    saveComment() {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "tasks/" + _this.task + "/comments",
          data: _this.commentCreate
        })
        .then(() => {
          _this.commentCreate = new Object({
            description: null,
            attachments: []
          });
          _this.commentActive = false;
          _this.getComments();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    deleteComment(itemid) {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "tasks/" + _this.task + "/comments/" + itemid
        })
        .then(() => {
          _this.getComments();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    getTimesheets() {
      const _this = this;
      _this.timesheetLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task + "/timesheets"
        })
        .then(({ data }) => {
          _this.timesheets = data;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timesheetLoading = false;
        });
    },
    addTimesheet() {
      this.timesheetActive = !this.timesheetActive;
      this.timesheetCreate = new Object({
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        member: null,
        description: null
      });
    },
    setStartDate(param) {
      this.timesheetCreate.start_date = param;
    },
    setEndDate(param) {
      this.timesheetCreate.end_date = param;
    },
    setStartTime(param) {
      this.timesheetCreate.start_time = param;
    },
    setEndTime(param) {
      this.timesheetCreate.end_time = param;
    },
    saveTimesheet() {
      const _this = this;
      _this.timesheetLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "tasks/" + _this.task + "/timesheets",
          data: {
            user: _this.timesheetCreate.member,
            started_at: moment(
              _this.timesheetCreate.start_date +
                " " +
                _this.timesheetCreate.start_time
            ).format("YYYY-MM-DD HH:MM"),
            finished_at: moment(
              _this.timesheetCreate.end_date +
                " " +
                _this.timesheetCreate.end_time
            ).format("YYYY-MM-DD HH:MM"),
            description: _this.timesheetCreate.description
          }
        })
        .then(() => {
          _this.timesheetCreate = new Object({
            start_date: null,
            start_time: null,
            end_date: null,
            end_time: null,
            member: null,
            description: null
          });
          _this.timesheetActive = false;
          _this.getTimesheets();
          _this.getTask();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timesheetLoading = false;
        });
    },
    deleteTimesheet(itemid) {
      const _this = this;
      _this.timesheetLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "tasks/" + _this.task + "/timesheets/" + itemid
        })
        .then(() => {
          _this.getTimesheets();
          _this.getTask();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timesheetLoading = false;
        });
    },
    getAttachments() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task + "/attachments"
        })
        .then(({ data }) => {
          _this.attachments = data;
        })
        .catch(error => {
          _this.logError(error);
        });
    },
    addAttachment() {
      this.attachmentActive = !this.attachmentActive;
      this.attachmentCreate = new Object({
        attachments: []
      });
    },
    updateAttachment(param) {
      this.attachmentCreate.attachments = param;
    },
    saveAttachment() {
      const _this = this;
      _this.attachmentLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "tasks/" + _this.task + "/attachments",
          data: _this.attachmentCreate
        })
        .then(() => {
          _this.attachmentCreate = new Object({
            attachments: []
          });
          _this.attachmentActive = false;
          _this.getAttachments();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.attachmentLoading = false;
        });
    },
    getTask() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task
        })
        .then(({ data }) => {
          _this.moreActions = data.more_actions;
          _this.taskDetail = new Object({
            subject: data.subject,
            description: data.description,
            barcode: data.barcode,
            reference: data.reference,
            hourly_rate: data.hourly_rate,
            priority: data.priority,
            status: data.status,
            recurrence: data.recurrence,
            recurrence_text: data.recurrence_text,
            duration: data.duration,
            duration_type: data.duration_type,
            duration_type_text: data.duration_type_text,
            total_cycle: data.total_cycle,
            start_date: data.start_date,
            finish_date: data.finish_date,
            attachments: data.attachments,
            due_date: data.due_date,
            members: data.members
          });
          if (data.members && data.members.length > 0) {
            _this.members = data.members.map(row => {
              return row.user_id;
            });
          }
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Task", route: "task" },
      { title: "Detail" }
    ]);

    _this.task = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.task <= 0) {
      _this.goBack();
    }

    _this.getTask();
    _this.getMembers();
    _this.getChecklistItems();
    _this.getComments();
    _this.getTimesheets();
    _this.getAttachments();
  },
  computed: {
    taskSubject() {
      return this.taskDetail.subject;
    },
    taskMemberList() {
      let result = this.memberList.map(row => {
        if (this.lodash.includes(this.members, row.id)) {
          return row;
        }
        return null;
      });
      return this.lodash.compact(result);
    },
    isPageLoading() {
      if (
        this.deleteLoading ||
        this.checklistLoading ||
        this.timesheetLoading ||
        this.commentLoading ||
        this.pageLoading
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>
