<template>
  <v-container
    fluid
    class="white-background main-detail-page"
    :class="[customClass]"
  >
    <div class="p-5 pb-0" :class="[customSecondClass]">
      <v-row>
        <v-col cols="7" class="pt-0 my-auto">
          <slot name="header-title"></slot>
        </v-col>
        <v-col cols="5" class="pt-0 text-right">
          <slot name="header-action"></slot>
        </v-col>
      </v-row>
    </div>
    <slot name="body"></slot>
    <slot name="footer"></slot>
  </v-container>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: null
    },
    customSecondClass: {
      type: String,
      default: null
    }
  }
};
</script>
